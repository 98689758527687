import * as React from "react";
import "../styles/index.scss";
import TwoColumn, {
  ImageColumn,
  TextColumn,
  ButtonColumn,
} from "../components/TwoColumn";
import pdf from "../../static/EBP-BR-EN-2110.1.pdf";
import Section from "../components/Section";
import WelcomeSection from "../components/WelcomeSection";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

// markup
const IndexPage = () => {
  const images = {
    woman_at_work: {
      src: "/images/woman_at_work.webp",
      alt: "Woman",
    },
    discussion: {
      src: "/images/discussion.jpeg",
      alt: "Discussion",
    },
    scheme: {
      src: "/images/scheme.png",
      alt: "Scheme",
    },
  };

  return (
    <>
      <Seo />
      <Header />
      <main>
        <WelcomeSection>
          <h1 className="welcome_title">
            Welcome at your SaaS for Global Benefits Management
          </h1>
        </WelcomeSection>
        <Section>
          <h2 id="about" className="section_title">
            Our goal is to provide you with actionable Global Benefits
          </h2>
          <TwoColumn>
            <ImageColumn image={images["woman_at_work"]} />
            <TextColumn
              title="Albeit being experts in this area, we do not sell insurance services"
              description="More that 30 years of experience in Global Benefits Management"
            />
          </TwoColumn>
        </Section>
        <Section>
          <h2 id="services" className="section_title">
            SaaS for Global Benefits Management
          </h2>
          <TwoColumn reverse>
            <ImageColumn image={images["scheme"]} />
            <TextColumn
              title="EBplace Operational Model"
              description="On our platform, Partners in insurance digitally unite their strengths. "
            />
          </TwoColumn>
          <TwoColumn
            img="discussion"
            title="Restricted Area for licensed users"
            button={{ text: "Login" }}
          >
            <ImageColumn image={images["discussion"]} />
            <ButtonColumn
              title="Restricted Area for licensed users"
              button={{ text: "Login" }}
            />
          </TwoColumn>
        </Section>
        <Section>
          <h2 id="presentation" className="section_title">
            Sales Presentation
          </h2>
          <a href={pdf}>
            <div className="pdf-download">
              <span>
                <svg
                  style={{
                    marginRight: "1rem",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>{" "}
                EBP-BR-EN-2110.1
              </span>
              <span>Herunterladen</span>
            </div>
          </a>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
