import * as React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";

const TwoColumn = ({ children, reverse }) => {
  return (
    <Row
      className={`content-block description-with-image ${
        reverse ? "flex-row-reverse" : null
      }`}
    >
      {children}
    </Row>
  );
};

export default TwoColumn;

export const ImageColumn = ({ image }) => {
  return (
    <Col className="description-with-image__image" sm={{ span: 6, offset: 0 }}>
      <Image src={image.src} alt={image.alt} fluid rounded />
    </Col>
  );
};

export const TextColumn = ({ title, description }) => {
  return (
    <Col className="description-with-image__text" sm={{ span: 6, offset: 0 }}>
      <h3 className="block-title">{title}</h3>
      <p>{description}</p>
    </Col>
  );
};

export const ButtonColumn = ({ button, title }) => {
  return (
    <Col className="description-with-image__text" sm={{ span: 6, offset: 0 }}>
      <h3 className="block-title">{title}</h3>
      <Button size="lg" variant="secondary">
        {button.text}
      </Button>
    </Col>
  );
};
