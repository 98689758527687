import * as React from "react";
import { Container } from "react-bootstrap";
import bgImage from "../../static/hero.webp";

const WelcomeSection = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(22, 22, 22, 0.25) 0%, rgba(22, 22, 22, 0.25) 100%), url(${bgImage})`,
        backgroundColor: "#222222",
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        minHeight: `50vh`,
      }}
      className="welcome section"
    >
      <Container>{children}</Container>
    </div>
  );
};

export default WelcomeSection;
