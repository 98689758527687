import * as React from "react";
import { Container } from "react-bootstrap";

const Section = ({ children }) => {
  return (
    <div className="section">
      <Container>{children}</Container>
    </div>
  );
};

export default Section;
